import { Swiper, SwiperSlide } from 'swiper/react'
import { Lazy, Navigation, Pagination } from 'swiper'

const AproveiteBeneficios = () => {
  return (
    <section className="aproveite-beneficios desk">
      <div className="img-bg">
        <div> </div>
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/image-bg-cartao.png"
          alt="background carousel"
        />
      </div>
      <div className="content-wrapper">
        <div>
          <h2>Aproveite benefícios exclusivos</h2>
          <ul className="pictures-beneficios">
            <Swiper
              className="slick-slide-swiper-cartao"
              slidesPerView={3}
              pagination={{ clickable: true }}
              spaceBetween={20}
              lazy={{
                loadPrevNextAmount: 3,
                loadPrevNext: true,
              }}
              modules={[Navigation, Lazy, Pagination]}
            >
              {/* <SwiperSlide>
                <li className="foto-carrosel">
                  <img
                    src="https://decathlonpro.vteximg.com.br/arquivos/20220322-beneficio-01.png"
                    alt=""
                  />
                </li>
              </SwiperSlide> */}
              <SwiperSlide>
                <li className="foto-carrosel">
                  <img
                    src="https://decathlonpro.vteximg.com.br/arquivos/20220322-beneficio-02.png"
                    alt=""
                  />
                </li>
              </SwiperSlide>
              <SwiperSlide>
                <li className="foto-carrosel">
                  <img
                    src="https://decathlonpro.vteximg.com.br/arquivos/20220322-beneficio-03.png"
                    alt=""
                  />
                </li>
              </SwiperSlide>
              <SwiperSlide>
                <li className="foto-carrosel">
                  <img
                    src="https://decathlonpro.vteximg.com.br/arquivos/20220322-beneficio-04.png"
                    alt=""
                  />
                </li>
              </SwiperSlide>
              <SwiperSlide>
                <li className="foto-carrosel">
                  <img
                    src="https://decathlonpro.vteximg.com.br/arquivos/descontos-itau.png"
                    alt=""
                  />
                </li>
              </SwiperSlide>
            </Swiper>
          </ul>
          <p>
            * benefícios de frete grátis e parcelamento são válidos apenas para
            produtos vendidos e entregues pela Decathlon
          </p>
        </div>
      </div>
    </section>
  )
}

export default AproveiteBeneficios
