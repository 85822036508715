const BannerTop = () => {
  return (
    <section className="container-cartao banner-top">
      <a
        className="desk"
        href="https://www.itau.com.br/cartoes/escolha/formulario/decathlon-itaucard.html?utm_source=decathlon&utm_medium=parceria&utm_campaign=banner_home"
      >
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/20220322-banner01-principal-desk.png"
          alt="banner top"
        />
      </a>
      <a
        className="mob"
        href="https://play.google.com/store/apps/details?id=com.odete.decathlon"
      >
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/20220322-banner01-principal-mobile%20(1).png"
          alt="banner top"
        />
      </a>
    </section>
  )
}

export default BannerTop
