const BarFixed = () => {
  return (
    <section className="bar-fixed">
      <div className="desk">
        <p>Cartão Decathlon Itaú</p>
        <a href="https://www.itau.com.br/cartoes/escolha/formulario/decathlon-itaucard.html?utm_source=decathlon&utm_medium=parceria&utm_campaign=banner_home">
          peça já
        </a>
      </div>
      <div className="mob">
        <a
          className="peca-ja"
          href="https://play.google.com/store/apps/details?id=com.odete.decathlon"
        >
          peça já
        </a>
      </div>
    </section>
  )
}

export default BarFixed
