const RegrasCartao = () => {
  return (
    <section className="regras-cartao">
      <div className="img-content">
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/20220322-banner-regras.png"
          alt="rules card"
        />
      </div>
      <div className="text-content">
        <p>
          regras do contrato do cartão{' '}
          <a href="https://www.itau.com.br/assets/dam/publisher/06_cartoes/34_decathlon/tarifario_e_contratos/contrato/contrato_decathlon.pdf">
            clicando aqui
          </a>
        </p>
        <p>
          regras do tarifário{' '}
          <a href="https://www.itau.com.br/assets/dam/publisher/06_cartoes/34_decathlon/tarifario_e_contratos/tarifario/tarifario_decathlon.pdf">
            clicando aqui
          </a>
        </p>
      </div>
    </section>
  )
}

export default RegrasCartao
