import AproveiteBeneficios from './AproveiteBeneficios'
import AproveiteBeneficiosMob from './AproveiteBeneficiosMob'
import BannerMiddle from './BannerMiddle'
import BannerTop from './BannerTop'
import BarFixed from './BarFixed'
import Questions from './Questions'
import RegrasCartao from './RegrasCartao'
import './styles.scss'

const CartaoDecathlon = () => {
  return (
    <>
      <main className="main-cartao" id="main-cartao">
        <BannerTop />
        <BarFixed />
        <AproveiteBeneficios />
        <AproveiteBeneficiosMob />
        <BannerMiddle />
        <RegrasCartao />
        <Questions />
      </main>
    </>
  )
}

export default CartaoDecathlon
