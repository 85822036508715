import { useCallback, useState } from 'react'

interface Props {
  title: string
  content: string
}

function QuestionAnswerCartao(props: Props) {
  const [setActive, setActiveState] = useState('')
  const [openPanel, setOpenPanel] = useState(false)

  const toggleAccordion = useCallback(() => {
    setActiveState(setActive === '' ? 'active' : '')
    setOpenPanel(!openPanel)
  }, [openPanel, setActive])

  return (
    <>
      <button className={`pergunta ${setActive}`} onClick={toggleAccordion}>
        {props.title}
      </button>
      {openPanel && (
        <div className="panel">
          <div dangerouslySetInnerHTML={{ __html: props.content }} />
        </div>
      )}
    </>
  )
}

export default QuestionAnswerCartao
