import QuestionAnswerCartao from './QuestionAnswerCartao'

const Questions = () => {
  return (
    <section id="duvidas" className="questions-cartao">
      <h2>Perguntas Frequentes</h2>
      <div className="content__questions">
        <QuestionAnswerCartao
          title="O que é o cartão Decathlon?"
          content="<p>
            Decathlon Itaú é um cartão de crédito criado pelo Itaú e pela
            Decathlon com o objetivo de promover benefícios para você fazer o
            que te faz bem, como: descontos em produtos esportivos, frete
            grátis, parcelamento diferenciado, parceiros exclusivos. Além disso
            você ainda conta com um aplicativo exclusivo para acompanhamento de
            movimentações do cartão, visualizar fatura, gerar cartão virtual,
            alterar limite, pedir 2ª via, bloquear cartão e muito mais!
          </p>"
        />
        <QuestionAnswerCartao
          title="Como solicito o meu cartão Decathlon?"
          content='
            <p>
              Para solicitar o cartão Decathlon é super simples. Baixe o
              aplicativo Decathlon Itaú, clique em "quero cartão" e envie sua proposta. A
              aprovação leva em torno de 30 minutos para ser aprovada. Em seguida
              você já poderá usar o app e gerar o seu cartão virtual para comprar
            </p>
          '
        />

        <QuestionAnswerCartao
          title="Os benefícios exclusivos do cartão, são válidos para todos os
          produtos?"
          content="
            <p>
              Os benefícios do cartão Decathlon Itaú são válidos apenas para os
              produtos vendidos e entregues pela Decathlon.
            </p>
          "
        />

        <QuestionAnswerCartao
          title="O cartão Decathlon Itaú possui programa de pontos?"
          content="
            <p>O cartão não possui programa de pontos atrelado a ele.</p>
          "
        />

        <QuestionAnswerCartao
          title="Posso gerar um cartão virtual mesmo sem ter um cartão físico em mãos?"
          content="
            <p>
              Pode sim! Caso o cartão já tenha sido aprovado, mas o físico ainda
              não chegou, você pode gerar o cartão virtual pelo app. Não precisa
              esperar o cartão físico chegar!
            </p>
          "
        />

        <QuestionAnswerCartao
          title="Quais são os canais de atendimento para eventuais dúvidas?"
          content="
            <p>
              Você pode entrar em contato com o suporte do Itaú, através dos seguintes canais: <br><br>

              Chat: Acesse o app Decathlon Itaú > Ajuda > Chat <br><br>
              Central de atendimento: 0800 055 0001 <br><br>
              SAC: 0800 724 4845 <br><br>
              Ouvidoria: 0800 570 0011 <br><br>
              Deficientes auditivos/fala: 0800 724 4838
            </p>
          "
        />
      </div>
    </section>
  )
}

export default Questions
