const AproveiteBeneficiosMob = () => {
  return (
    <section className="aproveite-beneficios mob">
      <div>
        <h2>Aproveite benefícios exclusivos</h2>
        <ul className="pictures-beneficios-mobile">
          <li className="foto-carrosel">
            <img
              src="https://decathlonpro.vteximg.com.br/arquivos/20220322-beneficio-01.png"
              alt=""
            />
          </li>
          <li className="foto-carrosel">
            <img
              src="https://decathlonpro.vteximg.com.br/arquivos/20220322-beneficio-02.png"
              alt=""
            />
          </li>
          <li className="foto-carrosel">
            <img
              src="https://decathlonpro.vteximg.com.br/arquivos/20220322-beneficio-03.png"
              alt=""
            />
          </li>
          <li className="foto-carrosel">
            <img
              src="https://decathlonpro.vteximg.com.br/arquivos/20220322-beneficio-04.png"
              alt=""
            />
          </li>
          <li className="foto-carrosel">
            <img
              src="https://decathlonpro.vteximg.com.br/arquivos/descontos-itau.png"
              alt=""
            />
          </li>
        </ul>
        <p>
          * benefícios de frete grátis e parcelamento são válidos apenas para
          produtos vendidos e entregues pela Decathlon
        </p>
      </div>
    </section>
  )
}

export default AproveiteBeneficiosMob
