const BannerMiddle = () => {
  return (
    <section className="banner-middle">
      <img
        src="https://decathlonpro.vteximg.com.br/arquivos/20220322-banner-cartao.png"
        alt="banner middle"
      />
      <img
        src="https://decathlonpro.vteximg.com.br/arquivos/informacoes-itau.png"
        alt="banner middle"
      />
    </section>
  )
}

export default BannerMiddle
